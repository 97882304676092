import React, { useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";

import QRCODE from "./qr-code.png";
import IMG from "./Origin-Summer-Fest.png";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";

import Jonny_Sunday_Event from "./events/Jonny_Sunday_Event";
import Vip from "./events/vip";
import Sunday_Event from "./events/sunday_event";
import Saturday_Event from "./events/saturday_event";
import Sat_Sun_Mon_Basket_Ball_Event from "./events/Sat_Sun_Mon_Basket_Ball_Event";
import Sat_Sun_Mon_Expo_Event from "./events/Sat_Sun_Mon_Expo_Event";

const defaultTheme = createTheme();

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#ffbf00",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: "#ffbf00",
    },
  },
  "&:not(.Mui-selected)": {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>
      {"Copyright © "}
      afro ticket sales {new Date().getFullYear()}
    </Typography>
  );
}

const Buy = () => {
  const [alignment, setAlignment] = useState("left");
  const [error, setError] = useState({
    currency: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    tx_ref: "",
    amount: "",
  });

  const [data, setdata] = useState({
    currency: "ETB",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    tx_ref: faker.string.uuid(),
    amount: "3",
    // return_url: process.env.REACT_APP_CURRENT_URL + `/Certificate`,
  });

  const navigate = useNavigate();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setdata({
      ...data,
      [name]: value,
    });
  };

  const validate = () => {
    let newErrors = {};
    if (!data.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Email is invalid";
    }

    const phoneRegex = /^(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    const nameRegex = /^[A-Za-z]+$/;

    if (!data.phone_number) {
      newErrors.phone_number = "Phone number is required";
    } else if (!phoneRegex.test(data.phone_number)) {
      newErrors.phone_number = "Phone number is invalid";
    }
    if (!data.first_name) {
      newErrors.first_name = "First Name is required";
    } else if (!nameRegex.test(data.first_name)) {
      newErrors.first_name = "First Name is invalid";
    }
    if (!data.last_name) {
      newErrors.last_name = "Last Name is required";
    } else if (!nameRegex.test(data.last_name)) {
      newErrors.last_name = "Last Name is invalid";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      localStorage.setItem("tx_ref", data.tx_ref);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CHAPA_PAY_BACKEND_TOKEN}`,
          },
        };
        const response = await axios.post(
          `${process.env.REACT_APP_CHAPA_PAY_BACKEND}/chapas/acceptpayment`,
          data,
          config
        );

        console.log(response.data, "this is chapa req");
        window.open(response.data);
      } catch (error) {
        console.error("An error occurred:", error);
        if (error.response) {
          setError({
            amount: error.response?.data?.error?.message?.amount
              ? error.response?.data?.error?.message?.amount[0]
              : null,
          });
        } else if (error.request) {
          console.error("Request error:", error.request);
          setError("No response received from the server.");
        } else {
          console.error("Error message:", error.message);
          setError(error.message);
        }
      }
    }
  };

  return (
    <div >
      <Jonny_Sunday_Event> </Jonny_Sunday_Event>
      <Vip> </Vip>
      <Sunday_Event> </Sunday_Event>
      <Saturday_Event> </Saturday_Event>
      <Sat_Sun_Mon_Basket_Ball_Event> </Sat_Sun_Mon_Basket_Ball_Event>
      <Sat_Sun_Mon_Expo_Event> </Sat_Sun_Mon_Expo_Event>
    </div>
  );
};

export default Buy;

// import logo from "./logo.svg";
import "./App.css";

import Buy from "./Buy";

import Jonny_Sunday_Event from "./events/Jonny_Sunday_Event";
import Sunday_Event from "../src/events/sunday_event";
import Saturday_Event from "./events/saturday_event";
import Sat_Sun_Mon_Basket_Ball_Event from "./events/Sat_Sun_Mon_Basket_Ball_Event";
import Sat_Sun_Mon_Expo_Event from "./events/Sat_Sun_Mon_Expo_Event";
import Chapa from "./events/chapa_transation_history";
import Vip from  "./events/vip"
import Certificate from "./Certificate";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
}
if (process.env.NODE_ENV === 'development') {
  // console.log = () => {};
  // console.debug = () => {};
  // console.info = () => {};
  // console.warn = () => {};
  // console.error = () => {};
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Buy />} />



        <Route path="/Chapa" element={<Chapa />} />
        <Route path="/Vip" element={<Vip />} />
        <Route path="/Jonny_Sunday_Event" element={<Jonny_Sunday_Event />} />
        <Route path="/Sunday_Event" element={<Sunday_Event />} />
        <Route path="/Saturday_Event" element={<Saturday_Event />} />
        <Route
          path="/Sat_Sun_Mon_Basket_Ball_Event"
          element={<Sat_Sun_Mon_Basket_Ball_Event />}
        />
        <Route
          path="/Sat_Sun_Mon_Expo_Event"
          element={<Sat_Sun_Mon_Expo_Event />}
        />
        <Route path="/Certificate" element={<Certificate />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';


import "primereact/resources/themes/lara-light-cyan/theme.css";
import axios from "axios"

// import { Dataervice } from './service/Dataervice';

const Chapa_transation_history = () => {

    const [Data, setData] = useState('')
    console.log(Data, 'this is data')
    const [pagination, setPagination] = useState({
        first_page_url: '',
        next_page_url: '',
        prev_page_url: '',
        per_page: 10,

        total: 0,
    });


    const [current_page, setcurrent_page] = useState(1)


    console.log(current_page, 'current page')
    const url = `${process.env.REACT_APP_CHAPA_PAY_BACKEND}/chapas/ticket_history`
    const data = { "current_page": current_page }
    const config = {
        headers: {

            Authorization: `Bearer ${process.env.REACT_APP_CHAPA_PAY_BACKEND_TOKEN}`,
        }
    };


    useEffect(() => {
        const getAllTransactionHistory = async () => {
            try {
                const response = await axios.post(
                    url,
                    data,
                    config
                );
                console.log(response.data, 'server response')
                setData(response.data.transactions); // Ensure you're setting the actual data
            } catch (error) {
                console.error("Error fetching transaction history:", error);
            }
        }

        getAllTransactionHistory(); // Call the function on first render
    }, [current_page]); // Empty dependency array ensures it runs only once on mount





    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        created_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        currency: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        amount: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        charge: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        ref_id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        payment_method: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        mobile: { value: null, matchMode: FilterMatchMode.CONTAINS },


    });
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');



    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>
        );
    };

    const status_data = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.status}</span>
            </div>
        );
    };

    const ref_id_data = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.ref_id}</span>
            </div>
        );
    };
    const created_at = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span  >{Date(rowData.created_at)}</span>
            </div>
        );
    };






    const amount = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.amount}</span>
            </div>
        );
    };

    const charge = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.trans_id}</span>
            </div>
        );
    };


    const payment_method = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.payment_method}</span>
            </div>
        );
    };


    const first_name = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.customer.first_name}</span>
            </div>
        );
    };
    const last_name = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.customer.last_name}</span>
            </div>
        );
    };


    const mobile = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">

                <span>{rowData.customer.mobile}</span>
            </div>
        );
    };




    const header = renderHeader();

    const handleNext = () => {



        setcurrent_page(current_page + 1)
    };

    const handlePrev = () => {

        if (current_page >= 2) {

            setcurrent_page(current_page - 1)
        }

    };




    // "status": "failed/cancelled",
    // "ref_id": "APXgUUiidsxL",
    // "type": "API",
    // "created_at": "2024-08-29T15:13:05.000000Z",
    // "currency": "ETB",
    // "amount": "2.000",
    // "charge": "0.070",
    // "trans_id": "10787019195143144997",
    // "payment_method": "cbebirr",
    //   "customer": {
    //     "id": 1503999,
    //     "email": "yohcinear@gmail.com",
    //     "first_name": "yohannes",
    //     "last_name": "zemene",
    //     "mobile": "251970716262"
    //   }
    return (

        <>

            <div style={{ display: 'flex', flexDirection: 'row', padding: 5 }} >
                <button onClick={(e) => handlePrev(e)} style={{ backgroundColor: 'lightgreen', borderColor: "lightgreen", color: 'white', padding: 5, width: 100, margin: 5, borderRadius: 5, border: '1px solid lightgreen' }}>Previous</button>
                <div style={{ backgroundColor: 'lightgreen', width: 30, height: 30,  alignSelf: 'center', alignContent: 'center' ,borderRadius:"50%"}}>

                    {current_page}
                </div>
                <button onClick={(e) => handleNext(e)} style={{ backgroundColor: 'lightgreen', borderColor: "lightgreen", color: 'white', padding: 5, width: 100, margin: 5 }}>Next</button>
            </div>
            <div className="card"    >

                <DataTable
                    value={Data}

                    filters={filters}
                    loading={loading}
                    globalFilterFields={['status', 'mobile', 'ref_id']} header={header} emptyMessage="No Data found.">

                    <Column header="mobile" filterField="mobile" style={{ minWidth: '0.2rem' }} body={mobile} filterPlaceholder="Search by mobile" />
                    <Column header="ref_id" filterField="ref_id" style={{ minWidth: '1rem',backgroundColor:'lightgray'  }} body={ref_id_data} filterPlaceholder="Search by ref_id" />
                    <Column header="amount" filterField="amount" style={{ minWidth: '0.2rem' }} body={amount} filterPlaceholder="Search by amount" />
                    <Column header="status" filterField="status" style={{ minWidth: '0.2rem' }} body={status_data} filterPlaceholder="Search by status" />

                    <Column header="payment_method" filterField="payment_method" style={{ minWidth: '0.2rem' }} body={payment_method} filterPlaceholder="Search by payment_method" />
                    <Column header="first_name" filterField="first_name" style={{ minWidth: '0.2rem' }} body={first_name} filterPlaceholder="Search by first_name" />
                    <Column header="last_name" filterField="last_name" style={{ minWidth: '0.2rem' }} body={last_name} filterPlaceholder="Search by last_name" />
                    <Column header="created_at" filterField="created_at" style={{ Width: '0.2rem' }} body={created_at} filterPlaceholder="Search by ref_id" />



                </DataTable>

            </div>
        </>
    );
}




export default Chapa_transation_history

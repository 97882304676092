import React, { useState } from "react";
import './Spinner.css'; // For custom styles
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel, FormControl, InputLabel, Select, MenuItem,
  Checkbox,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";

import QRCODE from "./QR/Saturday_Event.png"
import CBE from "./cbe.png";
import CHAPA from "./chapa.png";
import IMG  from "./Adaw-Fest/Adaw-Fest/Adwa-Fashion.png"
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { faker } from "@faker-js/faker";
import { useNavigate } from "react-router-dom";

const defaultTheme = createTheme();

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: "#ffbf00",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: "#ffbf00",
    },
  },
  "&:not(.Mui-selected)": {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
}));

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}>

      {"Copyright © "}
  afro ticket sales {" "}

      {new Date().getFullYear()}

    </Typography>
  );
}

const Buy = () => {
  const [selectedOption, setSelectedOption] = useState('1')
  console.log(selectedOption, 'number of ticket')
  const Total_Amount = selectedOption * 300 || 300
  console.log(Total_Amount, 'number of ticket')

  const [loading,setLoading] = useState(false)


  const [alignment, setAlignment] = useState("left");
  const [error, setError] = useState({
    currency: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    tx_ref: "",
    amount: "",
  });

  const [data, setdata] = useState({
    currency: "ETB",
    email: "afroticket178@gmail.com",
    first_name: "afroticket",
    last_name: "afroticket",
    phone_number: "",
    tx_ref: faker.string.uuid(),
    amount:  `${Total_Amount}`,
    Date:"Saturday, Sept 7, 2024",
    Time:"6:00pm - 10:00pm",
    Description: "Back to your Origin Summer Fest: Fashion Show"
    // return_url: process.env.REACT_APP_CURRENT_URL + `/Certificate`,
  });

  const navigate = useNavigate();

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setdata({
      ...data,
      [name]: value,
    });
  };

  const validate = () => {
    let newErrors = {};
    if (!data.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      newErrors.email = "Email is invalid";
    }

    const phoneRegex = /^(?:\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    const nameRegex = /^[A-Za-z]+$/;

    if (!data.phone_number) {
      newErrors.phone_number = "Phone number is required";
    } else if (!phoneRegex.test(data.phone_number)) {
      newErrors.phone_number = "Phone number is invalid";
    }
    if (!data.first_name) {
      newErrors.first_name = "First Name is required";
    } else if (!nameRegex.test(data.first_name)) {
      newErrors.first_name = "First Name is invalid";
    }
    if (!data.last_name) {
      newErrors.last_name = "Last Name is required";
    } else if (!nameRegex.test(data.last_name)) {
      newErrors.last_name = "Last Name is invalid";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      localStorage.setItem("tx_ref", data.tx_ref);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CHAPA_PAY_BACKEND_TOKEN}`,
          },
        };
        const response = await axios.post(
          `${process.env.REACT_APP_CHAPA_PAY_BACKEND}/chapas/acceptpayment`,
          data,
          config
        );

        console.log(response.data, "this is chapa req");
        window.open(response.data);
      } catch (error) {
        console.error("An error occurred:", error);
        if (error.response) {
          setError({
            amount: error.response?.data?.error?.message?.amount
              ? error.response?.data?.error?.message?.amount[0]
              : null,
          });
        } else if (error.request) {
          console.error("Request error:", error.request);
          setError("No response received from the server.");
        } else {
          console.error("Error message:", error.message);
          setError(error.message);
        }
      }
    }
  };
  const handleSubmit_Cbe = async () => {

    localStorage.setItem("tx_ref", data.tx_ref);
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_CBE_PAY_BACKEND}/cbes/collect-data`,
        data

      );

      console.log(response, "this is cbe request");


window.open(response.data, '_blank');


      // window.open(response);
    } catch (error) {
      console.error("An error occurred:", error);

      // setError(error.message);
    }

};
  return (
    <>
      <div className={loading ? "spinner" : ""}>



      </div>
      <div style={loading ? { backgroundColor: "lightgrey", opacity: '0.4' } : { backgroundColor: "lightgrey" }}>


        <div
          style={{
            transform: "scale(0.8)",
            borderRadius: 20,

            overflow: "hidden",
            // marginTop: -100,
          }}>
          <ThemeProvider theme={defaultTheme}>
            <Grid container component="main">
              {/* <CssBaseline /> */}
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                component={Paper}
                elevation={6}
                square
                sx={{
                  backgroundImage: `url(${IMG})`,
                  backgroundSize: "contain", // or 'contain', '100px 200px', etc.
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}></Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                component={Paper}
                elevation={6}
                square>
                <Box
                  sx={{
                    my: 6,
                    mx: 4,
                    flex: 1,
                  }}>
                  <Typography component="h1" variant="h5">
                    Buy Ticket
                  </Typography>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1 }}>


                    <FormControl fullWidth margin="normal">
                      <InputLabel id="dropdown-label">Choose Number Of Ticket</InputLabel>
                      <Select
                        labelId="dropdown-label"
                        value={selectedOption}
                        onChange={(event) => {
                          setSelectedOption(event.target.value);

                          setdata((prevData) => ({ ...prevData, "amount": 500 * event.target.value }))
                        }}
                        label="Choose an option"
                      >
                        <MenuItem value="">Select Number Of Ticket</MenuItem>
                        <MenuItem value="1">1 Ticket</MenuItem>
                        <MenuItem value="2">2 Ticket</MenuItem>
                        <MenuItem value="3">3 Ticket</MenuItem>
                        <MenuItem value="4">4 Ticket</MenuItem>
                        <MenuItem value="5">5 Ticket</MenuItem>
                        <MenuItem value="6">6 Ticket</MenuItem>
                        <MenuItem value="7">7 Ticket</MenuItem>
                        <MenuItem value="8">8 Ticket</MenuItem>
                        <MenuItem value="9">9 Ticket</MenuItem>
                        <MenuItem value="10">10 Ticket</MenuItem>
                        <MenuItem value="11">11 Ticket</MenuItem>
                        <MenuItem value="12">12 Ticket</MenuItem>
                        <MenuItem value="13">13 Ticket</MenuItem>
                        <MenuItem value="14">14 Ticket</MenuItem>
                        <MenuItem value="15">15 Ticket</MenuItem>


                      </Select></FormControl>
                    <div
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}>
                      {error.phone_number}
                    </div>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="phone_number"
                      label="Phone Number"
                      name="phone_number"
                      autoComplete="phone_number"
                      onChange={handleChange}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      disabled
                      name="Date"
                      value={data.Date}
                      autoComplete="phone_number"
                      onChange={handleChange}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      disabled
                      multiline
                      name="Description"
                      value={data.Description}
                      onChange={handleChange}
                    />


                    <Typography
                      style={{
                        color: "#D4AF37",
                        fontWeight: "500",
                        textTransform: "capitalize",
                        marginTop: "0px",
                        fontSize: "24px",
                      }}>
                      Price {Total_Amount} ETB
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>


                      <Button
                        fullWidth
                        style={{ backgroundColor: "#D4AF37", height: 100, color: 'white', fontSize: 24 }}

                        onClick={handleSubmit}>
                        Buy Ticket  With
                        <img
                          src={CHAPA}
                          style={{ height: 80, width: 80, marginLeft: 20, borderRadius: 10 }}></img>
                      </Button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>

                      <Button
                        fullWidth
                        style={{ backgroundColor: "#D4AF37", height: 100, color: 'white', fontSize: 24 }}
                        onClick={handleSubmit_Cbe}>
                        Buy Ticket With


                        <img
                          src={CBE}
                          style={{ height: 80, width: 80, marginLeft: 20, borderRadius: 10 }}></img>
                      </Button>
                    </div>





                    <Copyright sx={{ mt: 5 }} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
};

export default Buy;

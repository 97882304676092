import React, { Component } from 'react'

const Certificate = () => {

    return (
      <div>
        <div> divInComponent </div>
      </div>
    )

}

export default Certificate
